<template>
  <div class="visual-mode-dark auth">
    <div class="uikit-layout">

      <DashboardHeader />

      <main>
        <router-view /> <!-- This will render the current route's component -->
      </main>
      <footer>
        <!-- UIkit footer component here -->
      </footer>
    </div>
  </div>
</template>
  
<script>
  import { ref, provide, onMounted } from 'vue';
  import DashboardHeader from '@/views/auth/DashboardHeader.vue';

  export default {
    name: 'AuthPresentation',
    components: {
      DashboardHeader,
    },
    setup() {
      const memberName = ref('');
      const profileImage = ref('');
      const email = ref('');  // New ref for email
      const apiKey = ref('');  // New ref for api_key
      const placeholderImage = require('@/assets/user_placeholder.jpg'); // Local fallback image

      const fetchAndProvideMemberName = async () => {
        try {
          const token = localStorage.getItem('authToken');
          if (!token) throw new Error('No authentication token found');

          const response = await fetch('https://epearson.me:3000/api/member-info', {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (!response.ok) throw new Error('Failed to fetch member name');
          const result = await response.json();
          if (!result.member_name) throw new Error('Member name not found');

          memberName.value = result.member_name; // Update the member name
          email.value = result.email || '';  // Update email from response
          apiKey.value = result.api_key || '';  // Update api_key from response
        } catch (error) {
          console.error('Error fetching member name and details:', error);
        }
      };

      const fetchProfileImage = async () => {
        const baseUrl = 'https://epearson.me:3000';
        const memberInfoUrl = `${baseUrl}/api/member-info`;
        const profileUrl = `${baseUrl}/api/member-profile`;

        try {
          const token = localStorage.getItem('authToken');
          if (!token) throw new Error('No authentication token found');

          // Step 1: Get member_id (if not already stored)
          let memberId = localStorage.getItem('memberId');
          if (!memberId) {
            const memberInfoResponse = await fetch(memberInfoUrl, {
              headers: { Authorization: `Bearer ${token}` },
            });

            if (!memberInfoResponse.ok) throw new Error('Failed to fetch member_id');
            const memberInfo = await memberInfoResponse.json();
            memberId = memberInfo.member_id;

            if (!memberId) throw new Error('member_id not found in member-info response');

            // Store member_id for future use
            localStorage.setItem('memberId', memberId);
          }

          // Step 2: Fetch profile image using member_id
          const profileImageUrl = `${profileUrl}?member_id=${memberId}`;
          const profileResponse = await fetch(profileImageUrl, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (!profileResponse.ok) throw new Error(`Fetch failed with status ${profileResponse.status}`);
          const profileData = await profileResponse.json();
          profileImage.value = profileData.profile_image || ''; // Assign image URL or keep it blank
        } catch (error) {
          console.error('Error fetching profile image:', error.message);
          profileImage.value = ''; // Force fallback to placeholder
        }
      };

      provide('memberName', memberName); // Provide memberName to child components
      provide('profileImage', profileImage); // Provide profileImage to child components
      provide('email', email); // Provide email to child components
      provide('apiKey', apiKey); // Provide apiKey to child components

      onMounted(() => {
        fetchAndProvideMemberName();
        fetchProfileImage();
      });

      return {
        memberName, // Expose for potential parent usage
        profileImage,
        email,
        apiKey,
        placeholderImage,
      };
    },
    mounted() {
      this.setupToggleListener();
      this.setActiveMenuItem();
    },
    watch: {
      '$route'() {
        this.setActiveMenuItem();
      },
    },
    methods: {
      setupToggleListener() {
        document.querySelectorAll('.toggle-light-dark-mode').forEach((item) => {
          item.addEventListener('click', () => {
            document
              .querySelectorAll('.toggle-light-dark-mode')
              .forEach((el) => (el.hidden = !el.hidden));
            this.updateHtmlClass();
          });
        });
      },

      setActiveMenuItem() {
        const navItems = document.querySelectorAll('.uk-nav-default li');
        const currentPath = this.$route.path;

        navItems.forEach((item) => {
          const link = item.querySelector('a');
          if (link && link.getAttribute('href') === currentPath) {
            navItems.forEach((i) => i.classList.remove('uk-active'));
            item.classList.add('uk-active');
          }
        });
      },

      updateHtmlClass() {
        const lightMode =
          document.querySelector('.light-mode').parentElement.parentElement;
        const darkMode =
          document.querySelector('.dark-mode').parentElement.parentElement;

        if (lightMode.hidden) {
          document.documentElement.classList.add('visual-mode-dark');
          document.documentElement.classList.remove('visual-mode-light');
        } else if (darkMode.hidden) {
          document.documentElement.classList.add('visual-mode-light');
          document.documentElement.classList.remove('visual-mode-dark');
        }
      },
    },
  };
</script>

<style>
.light-mode,
.dark-mode,
.war-report-icon,
.war-participation-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 110% 110%;
  opacity: .7;
}

.fixed-bottom-nav li:hover .light-mode,
.fixed-bottom-nav li:hover .dark-mode {
  opacity: .9;
}

.light-mode {
  background-image: url(@/assets/light-mode.svg);
}

.war-report-icon {
  background-image: url(@/assets/war-report.svg);
}

.war-participation-icon {
  background-image: url(@/assets/war-participation.svg);
}

.dark-mode {
  background-image: url(@/assets/dark-mode.svg);
}

.offcanvas-parent .uk-offcanvas-bar .uk-nav-default .uk-nav-header {
  color: rgba(255,255,255,.7);
  font-size: .675rem;
  margin-bottom: 10px;
}

.offcanvas-parent .uk-offcanvas-bar .main-nav li:not(.uk-nav-header),
.offcanvas-parent .uk-offcanvas-bar .fixed-bottom-nav li:not(.uk-nav-header) {
  padding: 6px 12px;
}

.offcanvas-parent .uk-offcanvas-bar .main-nav .uk-active {
  background: #444;
  border-radius: 5px;
}

.offcanvas-parent {
  position: relative;
}

.fixed-bottom-nav {
  position: absolute;
  bottom: 20px;
}
</style>