<template>
  <div class="coming-soon-container uk-section uk-section-muted">
    <div class="uk-text-center">
      <h1 class="heading">Coming Soon</h1>
      <p class="message">We're working hard to get our site up and running. Stay tuned!</p>
      <div class="uk-margin-top">
        <router-link to="/twisted-syndicate/">
          <a class="uk-button uk-button-primary" href="#">Notify Me!</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon'
}
</script>

<style scoped>
.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f7f7f7;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.message {
  font-size: 1.2rem;
  color: #555;
}
</style>
