<template>
    <header class="header-content">
        <div class="uk-container uk-container-expand">
            <div uk-navbar="offset: -15;">

                <!--<div class="uk-navbar-left">
                    <ul class="uk-navbar-nav uk-text-muted uk-text-middle">
                        <li style="margin-right:20px;">
                            <a class="uk-navbar-toggle" uk-navbar-toggle-icon href="#offCanvasMenu" uk-toggle style="padding-top:2px;"></a>
                            <button class="uk-offcanvas-close" type="button" uk-close></button>

                            <div id="offCanvasMenu" class="uk-open" uk-offcanvas="mode: push;" style="visibility: visible;">
                                <div class="uk-offcanvas-bar">
                                        <SlideMenuContents />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>-->

                <div :class="['breadcrumbs', navbarClass]">

                    <ul class="uk-navbar-nav uk-text-muted uk-text-middle">
                        <li class="uk-active">
                            <h3 style="margin: 0;">
                                <span class="active-view">{{ pageTitle }}</span>
                            </h3>
                        </li>
                    </ul>

                </div>

                <div class="uk-navbar-right global-settings">

                    <ul class="uk-navbar-nav">
                        <li class="uk-active">
                            <a href="#">
                                <div class="avatar-wrapper large">
                                    <img 
                                        :src="profileImage || placeholderImage" 
                                        @error="loadPlaceholder" 
                                        alt="User Avatar" 
                                        class="avatar-image" 
                                        />
                                </div>
                            </a>
                            <div class="uk-navbar-dropdown">
                                <ul class="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                        <router-link to="/twisted-syndicate/settings">
                                            <span class="uk-margin-small-right" uk-icon="icon: cog"></span>
                                            Settings
                                        </router-link>
                                    </li>
                                    <li class="uk-nav-divider" style="margin: 15px 0;"></li>
                                    <li>
                                        <a href="#" @click="logout" class="uk-text-danger">
                                            <span class="uk-margin-small-right uk-text-danger" uk-icon="icon: sign-out"></span>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </header>
</template>

<script>
import { watch, inject, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
//import SlideMenuContents from '@/views/auth/SlideMenuContents.vue';

export default {
  name: 'DashboardHeader',
  /*components: {
    SlideMenuContents,
  },*/
  data() {
    return {
      notificationMessage: '',
      notificationType: '',
      placeholderImage: require('@/assets/user_placeholder.jpg'), // Local fallback image
    };
  },
  setup() {
    const route = useRoute();
    const memberName = inject('memberName');
    const profileImage = inject('profileImage');

    // Log injected values for debugging
    console.log('memberName:', memberName);
    console.log('profileImage:', profileImage);

    const pageTitle = ref('');
    const navbarClass = computed(() => {
        return route.path.endsWith('/dashboard') ? 'uk-navbar-left' : 'uk-navbar-center';
    });

    // Watch the route's meta title to update the pageTitle
    watch(
        () => route.meta.title,
        (newTitle) => {
        pageTitle.value = newTitle;
        },
        { immediate: true } // Trigger on initial load as well
    );

    return {
        memberName,
        profileImage,
        pageTitle,
        navbarClass,
    };
    },
  methods: {
    async logout() {
      try {
        // Optional: Call backend API to invalidate token
        const token = localStorage.getItem('authToken');
        if (token) {
          await fetch('https://epearson.me:3000/api/logout', {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        // Clear local storage
        localStorage.removeItem('authToken');
        localStorage.removeItem('memberId'); // Clear stored member_id if applicable

        // Redirect to login page
        this.$router.push('/twisted-syndicate/email');
      } catch (error) {
        console.error('Error during logout:', error);
        // Handle logout error if needed
      }
    },
    loadPlaceholder(event) {
      // Load placeholder when image fails
      event.target.src = this.placeholderImage;
    },
  },
};
</script>

<style>
.text-spacer {
    margin: 0 5px;
}

.header-content .active-view {
    color: #333;
}

.visual-mode-dark .header-content .active-view {
    color: #fff;
}

.small-screen-only {
    display: none;
    visibility: hidden;
}
</style>