<template>

  <button class="uk-button inactive uk-button-medium uk-button-primary replace-account-button">
    <span uk-icon="icon: pencil" style="margin-right:5px;position:relative;top:-2px;"></span>
    Edit Profile
  </button>

  <button class="uk-button uk-button-small uk-button-default back-button" style="position:absolute;top:18px;left:20px;" @click="$router.go(-1)">
      <span uk-icon="icon: arrow-left"></span>
  </button>

  <!-- Main Content Wrapper -->
  <div class="main-content">

    <div class="uk-container uk-container-expand uk-margin-top">

      <div class="avatar-wrapper xlarge object-centered">
          <img 
              :src="profileImage || placeholderImage" 
              @error="loadPlaceholder" 
              alt="User Avatar" 
              class="avatar-image" 
              />
      </div>
      <h1 class="uk-text-center uk-margin-top" style="margin-bottom:5px;">{{ memberName }}</h1>
      <p class="uk-text-center uk-margin-remove-top uk-text-primary">{{ email }}</p>

      <p style="margin-top:30px;margin-bottom:10px">YOUR API KEY</p>
      <div class="uk-card uk-card-secondary uk-card-body" style="margin-bottom: 30px; padding: 20px;">
        <p>{{ apiKey }}</p>
      </div>

      <p style="margin-bottom:10px">YOUR PASSWORD</p>
      <div class="uk-card uk-card-secondary uk-card-body" style="margin-bottom: 40px; padding: 20px;">
        <p>************</p>
      </div>


      <button class="uk-button uk-button-medium uk-button-danger uk-width-1-1" @click="logout">
        <span uk-icon="icon: sign-out" style="margin-right:2px;position:relative;top:-1px;"></span>
        Log out
      </button>


    </div>

  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'SettingsView',
  data() {
    return {
      placeholderImage: require('@/assets/user_placeholder.jpg'), // Local fallback image
    };
  },
  setup() {
    const memberName = inject('memberName');
    const profileImage = inject('profileImage');
    const email = inject('email');
    const apiKey = inject('apiKey');

    // Log injected values for debugging
    console.log('memberName:', memberName);
    console.log('profileImage:', profileImage);
    console.log('userEmail:', email);
    console.log('userApiKey:', apiKey);

    return {
      memberName,
      profileImage,
      email,
      apiKey
    };
  },
  methods: {
    loadPlaceholder(event) {
      // Load placeholder when image fails
      event.target.src = this.placeholderImage;
    },
    async logout() {
          try {
              // Optional: Call backend API to invalidate token
              const token = localStorage.getItem('authToken');
              if (token) {
                  await fetch('https://epearson.me:3000/api/logout', {
                      method: 'POST',
                      headers: { Authorization: `Bearer ${token}` },
                  });
              }

              // Clear local storage
              localStorage.removeItem('authToken');
              localStorage.removeItem('memberId'); // Clear stored member_id if applicable

              // Redirect to login page
              this.$router.push('/twisted-syndicate/email');
          } catch (error) {
              console.error('Error during logout:', error);
              // Handle logout error if needed
          }
      }
    },
};
</script>

<style scoped>

.uk-margin-top {
  width: 100%;
}

.slide-nav-target.offcanvas-parent {
  position: fixed;
  top: 80px;
}

.main-content {
  display: flex;
  justify-content: center;
  max-width: none;
}

.uk-button {
  border-radius: 40px;
  text-transform: initial;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: .25px;
  padding: 2px 15px;
}

.uk-button.inactive {
  background-color: #141414;
  border: 2px solid #1e87f0;
}

.uk-button.back-button {
    border-radius: 10px;
    padding: 0 10px 2px 10px;
    color: white;
    margin-bottom: 10px;
}

.uk-button.replace-account-button {
  position: absolute;
  top: 17px;
  right: 25px;
}

@media (max-width: 640px) {
  .uk-button.replace-account-button {
    right: 15px;
  }
}
</style>
