<template>
  <router-view /> <!-- This will render the current route's component -->
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Global styles */
</style>
