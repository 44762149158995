<template>
  <div v-if="isDataReady" class="uk-card uk-card-default uk-width-1-1">
    <div class="uk-card-header">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-width-auto">
          <div class="uk-form-controls">
            <div class="uk-inline uk-inline-select-overlayed">
              <label for="participantSelector">Member:</label>
              <select
                id="participantSelector"
                class="uk-select uk-form-width-custom-280 uk-form-small uk-text-right"
                v-model="selectedMemberId"
              >
                <option
                  v-for="member in formattedRosterMembers"
                  :key="member.value"
                  :value="member.value"
                >
                {{ member.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card-header">
      <h3 class="uk-card-title uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-default" style="line-height: 1.3; margin: 5px 0px; padding-top:0;">War Results - Full Participation</h3>
      <p class="uk-text-meta uk-margin-remove-top">Showing participation percentage for selected member.</p>
    </div>

    <div class="uk-card-body half-gauge-chart">
      <Doughnut :data="chartData" :options="chartOptions" />
      <div class="center-text">{{ percentage }}<span class="percent">%</span></div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register required components
ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    Doughnut,
  },
  props: {
    activeRosterMembers: {
      type: Array,
      required: true,
    },
    warParticipationData: {
      type: Object,
      required: true,
    },
    isDataReady: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedMemberId: null,
      percentage: 0,
      chartData: null,
      chartOptions: {
        responsive: true,
        rotation: -90,
        circumference: 180,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },

  computed: {
    // Sorted member list formatted as "member_name (member_id)"
    formattedRosterMembers() {
      return this.activeRosterMembers
        .map(member => ({
          label: `${member.member_name} (${member.member_id})`,
          value: member.member_id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  },

  watch: {
    // Watch for when isDataReady becomes true
    isDataReady(newVal) {
      if (newVal) {
        console.log("Data is ready:", newVal);
        
        // Once data is ready, set the topmost alpha member as the default selected member
        const firstMember = this.formattedRosterMembers[0];
        if (firstMember) {
          this.selectedMemberId = firstMember.value;
        }
      }
    },

    // Watch for changes in selectedMemberId to trigger chart update
    selectedMemberId() {
      this.updateChartData();
    },
  },

 methods: {
    // Updates chart data based on selected member's war participation details
    updateChartData() {
      console.log("updateChartData triggered with selectedMemberId:", this.selectedMemberId);
      // console.log("Formatted warParticipationData:", this.warParticipationData);

      // Ensure warParticipationData and selectedMemberId are available
      if (!Array.isArray(this.warParticipationData) || !this.selectedMemberId) {
        console.error("Invalid data: warParticipationData must be an array and selectedMemberId must be defined.");
        return;
      }

      // Find the selected member in the warParticipationData array
      const selectedMember = this.warParticipationData.find(member => member.member_id === this.selectedMemberId);

      // Log the result of the access operation
      // console.log("Selected member data:", selectedMember);

      // If the member is found, proceed with chart data extraction
      if (selectedMember) {
        const warsPresentCount = selectedMember.wars_present_count || 0;
        const warsParticipatedCount = selectedMember.wars_fully_participated_count || 0;
        const warsParticipatedPercentage = selectedMember.wars_full_participation_percentage || 0;

        // console.log("warsPresentCount:", warsPresentCount);
        // console.log("warsParticipatedCount:", warsParticipatedCount);
        // console.log("warsParticipatedPercentage:", warsParticipatedPercentage);

        // Calculate Not Participated count for chart display
        const notParticipatedCount = warsPresentCount - warsParticipatedCount;

        // Update chart data
        this.chartData = {
          labels: ['Participated', 'Not Participated'],
          datasets: [
            {
              data: [warsParticipatedCount, notParticipatedCount],
              backgroundColor: ['rgba(62, 101, 175, 1.0)', 'rgba(244, 244, 245, 1.0)'],
              borderColor: ['rgba(255, 255, 255, 1)'],
              borderWidth: 3,
              cutout: '80%',
            },
          ],
        };

        // Update the percentage displayed under the chart
        this.percentage = Math.round(warsParticipatedPercentage);
        console.log("Chart updated with data:", this.chartData);
      } else {
        console.log("Selected member not found in warParticipationData.");
      }
    }
  },

  mounted() {
    // Trigger data ready checks immediately after mount if the data is already ready
    if (this.isDataReady) {
      this.$nextTick(() => {
        console.log("Initial data ready check after mount");
        const firstMember = this.formattedRosterMembers[0];
        if (firstMember) {
          this.selectedMemberId = firstMember.value;
        }
      });
    }
  },
};
</script>

<style scoped>
.uk-card {
  margin-bottom: 20px;
}

.uk-form-width-custom-280 {
  width: 300px;
}

.uk-inline-select-overlayed .uk-select {
  padding-left: 60px !important;
  padding-right: 40px !important;
  text-align: right;
  border-radius: 5px;
}

.uk-card-default {
  border: 1px solid #e4e4e7 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.half-gauge-chart {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.half-gauge-chart canvas {
  height: 300px !important;
  width: 300px !important;
}

.half-gauge-chart .center-text {
  font-size: 65px;
  font-weight: bold;
  position: absolute;
  bottom: 44px;
  padding-left: 8px;
}

.half-gauge-chart .percent {
  color: #cccccc;
  font-size: 30px;
  vertical-align: super;
  padding-left: 2px;
}
</style>
