import { createRouter, createWebHistory } from 'vue-router';

import ComingSoon from '@/components/ComingSoon.vue';
import MasterPresentation from '@/components/MasterPresentation.vue';
import PublicPresentation from '@/components/PublicPresentation.vue';
import AuthPresentation from '@/components/AuthPresentation.vue';

import LandingPage from '@/views/public/LandingPage.vue';
import AccountEmail from '@/views/public/AccountEmail.vue';
import SignupView from '@/views/public/SignupView.vue';
import UpdatePassword from '@/views/public/UpdatePassword.vue';
import LoginView from '@/views/public/LoginView.vue';

import DashboardView from '@/views/auth/DashboardView.vue';
import SettingsView from '@/views/auth/SettingsView.vue';
import WarParticipationReport from '@/views/auth/WarParticipationReport.vue';
import WarReport from '@/views/auth/WarReport.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ComingSoon,
  },
  {
    path: '/twisted-syndicate/',
    component: MasterPresentation,
    children: [
      {
        path: '',
        component: PublicPresentation,
        children: [
          { path: '', component: LandingPage },
          { path: 'email', component: AccountEmail },
          { path: 'signup', component: SignupView },
          { path: 'update-password', component: UpdatePassword },
          { path: 'login', component: LoginView }
        ]
      },
      {
        path: '',
        component: AuthPresentation,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'dashboard',  // Use relative path
            component: DashboardView,
            meta: { title: 'Dashboard' }
          },
          {
            path: 'settings',  // Use relative path
            component: SettingsView,
            meta: { title: 'Settings' }
          },
          {
            path: 'war-report',  // Use relative path
            component: WarReport,
            meta: { title: 'War Report' }
          },
          {
            path: 'war-participation-report',  // Use relative path
            component: WarParticipationReport,
            meta: { title: 'War Participation Report' }
          }
        ]
      }      
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard for authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authToken');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/twisted-syndicate');  // Redirects to login page
  } else {
    next();  // Proceed with navigation
  }
});

export default router;
