<template>
    <DashboardHeader />

    <!-- Main Content Wrapper -->
    <div class="main-content">
  
      <div class="uk-container uk-container-expand">
  
        <!-- UIkit Headline -->
        <h5 class="uk-heading-primary uk-text-muted">Here's an overview of your total participant results by war.</h5>
  
        <div class="uk-child-width-1-1">
            <war-hits-widget 
                :factionData="factionData" 
                :warIds="formattedWarIds" 
                :selectedWarId="selectedWarId"
                @update:selectedWarId="handleWarIdChange"
            />
        </div>
  
        <!-- Error Messaging -->
        <p v-if="errorMessage" class="uk-text-danger">{{ errorMessage }}</p>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import WarHitsWidget from '@/views/auth/WarReportWidget.vue';
  import DashboardHeader from '@/views/auth/DashboardHeader.vue';
  import io from 'socket.io-client';
  import axios from 'axios';
  
  export default {
    components: {
      WarHitsWidget,
      DashboardHeader
    },
    data() {
      return {
        socket: null,
        warIds: [],
        formattedWarIds: [],
        selectedWarId: '', 
        warReportsData: {}, 
        errorMessage: '',
        factionData: [], 
        membershipData: [], 
        activeRosterMembers: [],
        isDataReady: false 
      };
    },
    mounted() {
      this.initializeSocket();
    },
    methods: {
      initializeSocket() {
        this.socket = io('http://82.112.250.106:3001');
        this.socket.on('connect', () => {
          this.fetchWarIds();
          this.fetchMembershipData(); 
        });
  
        this.socket.on('warIdsData', this.handleWarIdsData);
        this.socket.on('warReportByMemberData', this.handleWarReportData);
        this.socket.on('membershipData', this.handleMembershipData); 
        this.socket.on('api_error', this.handleApiError);
        this.socket.on('disconnect', () => {});
      },
      fetchWarIds() {
        if (this.socket && this.socket.connected) {
          this.socket.emit('fetchWarIds');
        } else {
          console.error('WebSocket connection is not open.');
        }
      },
      fetchAllWarReports() {
        if (this.socket && this.socket.connected) {
          this.socket.emit('fetchWarReportByMember'); 
        } else {
          console.error('WebSocket connection is not open.');
        }
      },
      fetchMembershipData() {
        if (this.socket && this.socket.connected) {
          this.socket.emit('fetchMembershipData'); 
        } else {
          console.error('WebSocket connection is not open.');
        }
      },
      handleWarIdChange(newWarId) {
        this.selectedWarId = newWarId;
      },
      handleWarIdsData(data) {
        this.warIds = data;
        this.formattedWarIds = this.warIds.map(item => {
          const date = new Date(item.end_timestamp * 1000);
          const formattedDate = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          }).toUpperCase();
          return {
            id: item.war_id,
            displayDate: formattedDate, 
            warId: item.war_id,        
            date: date
          };
        }).sort((a, b) => b.date - a.date);
  
        if (this.formattedWarIds.length > 0) {
          this.selectedWarId = this.formattedWarIds[0].id;
          this.fetchAllWarReports(); 
        } else {
          console.error('No War IDs available.');
        }
      },
      handleWarReportData(data) {
        if (Array.isArray(data)) {
          data.forEach(report => {
            if (report.war_id) {
              this.warReportsData[report.war_id] = report;
            }
          });
  
          this.formatFactionData();
        }
      },
      handleMembershipData(data) {
        this.membershipData = data;
      },
      formatFactionData() {
        this.factionData = Object.values(this.warReportsData).map(report => {
          const faction49411 = report.factions[49411]; 
  
          if (!faction49411) {
            return null; 
          }
  
          const totalAttacks = faction49411.attacks;
          const respect = Object.values(faction49411.members)
            .reduce((sum, member) => sum + member.score, 0); 
  
          return {
            war_id: report.war_id,
            attacks: totalAttacks,
            respect: respect,
            members: Object.entries(faction49411.members).map(([memberId, memberData]) => ({
              member_id: memberId,
              member_name: memberData.name,
              member_attacks: memberData.attacks,
              member_score: memberData.score
            }))
          };
        }).filter(Boolean); 
      },
  
      async fetchActiveRosterMembers() {
        try {
          const response = await axios.get('http://82.112.250.106:3000/api/twisted-minds/members?roster=active');
          this.activeRosterMembers = response.data; // Assign the fetched data to the new property
          //console.log('Active Roster Members:', this.activeRosterMembers); // Debugging log
        } catch (error) {
          console.error('Error fetching active roster members:', error);
          this.errorMessage = 'Failed to fetch active roster members.';
        }
      }
  
    }
  };
  </script>
  
  <style scoped>
  
  .uk-container-expand {
    width: 100%;
  }
  
  .slide-nav-target.offcanvas-parent {
    position: fixed;
    top: 80px;
  }
  
  .main-content {
    display: flex;
    justify-content: center;
    margin-left: 350px;
    max-width: none;
  }
  
  </style>
  