<template>
    <div class="hero-image"></div>

    <div class="uk-container email-page-format">
        <div class="uk-margin-large-left uk-margin-large-right">
            <div class="uk-flex uk-height-medium">
                <div class="uk-margin-auto uk-margin-auto-vertical uk-width-3-5@m">
                    <router-link to="/twisted-syndicate/login">
                        <button class="uk-button uk-button-small uk-button-default back-button uk-margin-large-top">
                            <span uk-icon="icon: arrow-left"></span>
                        </button>
                    </router-link>
                    <h1 class="uk-width-1-1 faction-summary">Update Password</h1>
                    <p class="uk-width-1-1 uk-margin-large-bottom">Using <span style="color: white">example@gmail.com</span> to login</p>
                    <form class="uk-form-stacked">
                        <div class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="memberName">PASSWORD</label>
                            <div class="uk-form-controls">
                                <input id="passwordField" class="uk-input" type="text" placeholder="Password">
                                <!-- Clear button -->
                                <button type="button" class="uk-form-icon uk-form-icon-flip clear-field" uk-icon="close" 
                                        onclick="document.getElementById('emailAddress').value = ''"></button>
                            </div>
                        </div>
                        <div class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="verifyPasswordField">VERIFY PASSWORD</label>
                            <div class="uk-form-controls">
                                <input id="verifyPasswordField" class="uk-input" type="text" placeholder="Verify Password">
                                <!-- Clear button -->
                                <button type="button" class="uk-form-icon uk-form-icon-flip clear-field" uk-icon="close" 
                                        onclick="document.getElementById('emailAddress').value = ''"></button>
                            </div>
                        </div>
                    </form>
                    <router-link to="/twisted-syndicate/overview">
                        <button class="uk-button uk-button-large uk-button-primary uk-width-1-1">
                            Sign In
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script></script>

<style scoped>
.hero-image {
    position: fixed;
    top: 0;
    left: 0;
    height: 70vh;
    background-image: url('~@/assets/twisted-syn.jpeg');
    background-size: cover;
    background-position: 0 -35px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .05;
    z-index: -1;
}

.hero-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: 
        /* First gradient for bottom fade */
        linear-gradient(to bottom, rgba(20, 20, 20, 0) 50%, #141414 100%),
        /* Subtle radial gradient for vignette effect */
        radial-gradient(circle at top left, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%),
        radial-gradient(circle at top right, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%);
    z-index: -1;
    /* Combine the gradients to achieve the fade effect */
}

.email-page-format {
    margin-top: 1vh;
}

.faction-summary {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.0rem;
}

.uk-button {
    border-radius: 40px;
    text-transform: initial;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .25px;
}

.uk-button.back-button {
    border-radius: 10px;
    padding: 0 10px 2px 10px;
    color: white;
    margin-bottom: 106px;
}

.uk-form-controls {
    margin-top: 20px;
    margin-bottom: 40px;
}

.uk-form-label {
    color: #666;
    letter-spacing: .5px;
}

#passwordField.uk-input,
#passwordField.uk-input:focus,
#passwordField.uk-input:active,
#passwordField.uk-input:hover,
#verifyPasswordField.uk-input,
#verifyPasswordField.uk-input:focus,
#verifyPasswordField.uk-input:active,
#verifyPasswordField.uk-input:hover {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #666;
    padding: 0 0 15px 0;
    outline: 0;
    box-shadow: none;
}

#passwordField.uk-input:focus,
#passwordField.uk-input:active,
#verifyPasswordField.uk-input:focus,
#verifyPasswordField.uk-input:active {
    color: #fff;
}

#passwordField:placeholder-shown + .clear-field,
#verifyPasswordField:placeholder-shown + .clear-field {
    top: -15px;
    opacity: 0;
    pointer-events: none;
}

#passwordField:not(:placeholder-shown) + .clear-field,
#verifyPasswordField:not(:placeholder-shown) + .clear-field {
    top: -15px;
    opacity: 1;
    pointer-events: all;
    color: white;
}
</style>