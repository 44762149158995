<template>
    <!--<header>Master Presentation</header>-->
    <main>
      <router-view />
    </main>
    <footer></footer>
  </template>
  
<script>

</script>

<style>

</style>