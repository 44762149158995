<template>
  <!-- Main Content Wrapper -->
  <div class="uk-width-1-1 uk-margin-top" style="padding: 0 40px;">
    <h1 v-if="memberName">Welcome back,<br> {{ memberName }}!</h1>
    <p v-else>Loading user information...</p>

    <div class="uk-width-1-1" style="margin-top: 50px;">
      <!-- Buttons styled as tabs -->
      <div class="uk-button-group tab-nav">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['uk-button', currentIndex === index ? 'uk-button-primary uk-active' : 'uk-button-default']"
          @click="selectTab(index)"
          uk-switcher-item="{{ index }}"
        >
          {{ tab }}
        </button>
      </div>

      <!-- Tab Content -->
      <ul class="uk-switcher" style="margin-top: 50px;">
        <!-- Tab 1: Overview -->
        <li>
          <div class="faction-status-wrapper uk-width-1-1">
            <div
              v-if="warStatus"
              :class="['faction-status', warStatus === 'at war' ? 'uk-alert-danger' : 'uk-alert-success']"
              uk-alert
            >
              <div class="decorative-wrapper">
                <div class="status-text-wrapper">
                  <p class="normal-status">{{ factionName }} is currently</p>
                  <p class="bold-status">{{ warStatus }}</p>
                </div>
              </div>
            </div>
          </div>
          <p>This is the Overview tab content. Add overview-related HTML here.</p>
        </li>

        <!-- Tab 2: Personal -->
        <li>
          <p>This is the Personal tab content. Add personal-related HTML here.</p>
        </li>

        <!-- Tab 3: Faction -->
        <li>
          <p>This is the Faction tab content. Include faction-related HTML here.</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import axios from 'axios';
import UIkit from 'uikit';

export default {
  name: 'DashboardView',
  data() {
    return {
      currentIndex: 0,
      tabs: ["Overview", "Personal", "Faction"], // Static tab names
      factionId: 49411, // TODO: Pass this dynamically
      warStatus: null,  // Holds the fetched faction war status
    };
  },
  mounted() {
    // Ensure UIkit's switcher recognizes the default active tab
    const switcher = UIkit.switcher(".uk-switcher");
    switcher.show(this.currentIndex);

    // Fetch the faction status on page load
    this.fetchFactionStatus();
  },
  setup() {
    const memberName = inject('memberName'); // Get memberName from parent

    return {
      memberName,
    };
  },
  methods: {
    selectTab(index) {
      this.currentIndex = index;

      // Ensure UIkit's switcher updates the active tab
      const switcher = UIkit.switcher(".uk-switcher");
      switcher.show(index);
    },
    async fetchFactionStatus() {
      try {
        const response = await axios.get(`https://epearson.me:3000/api/faction/${this.factionId}`);
        this.warStatus = response.data.war_status; // Update the war status
        this.factionName = response.data.name; // Update the faction name
      } catch (error) {
        console.error('Error fetching faction status:', error.message);
        this.warStatus = 'Error fetching status'; // Handle error gracefully
        this.factionName = 'Unknown'; // Handle error gracefully for name
      }
    },
  },
};
</script>

<style scoped>

.uk-margin-top {
  width: 100%;
}

.slide-nav-target.offcanvas-parent {
  position: fixed;
  top: 80px;
}

.uk-button {
  border-radius: 40px;
  text-transform: initial;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: .25px;
  padding: 2px 15px;
}

.tab-nav .uk-button {
  margin-right: 10px;
  padding: 2px 20px;
}

.tab-nav .uk-button-default {
  border-color: transparent;
  color: #474A56;
}

.faction-status {
  background-image: url('@/assets/smoke.jpg');
  background-size: cover;
  background-position: 0px 314px;
  width: 100%;
  height: 190px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .75px;
  color: rgba(255, 255, 255, 0.6);
  padding: 1px 0;
  border: 2px solid #2a2922;
  box-shadow: inset 0px 0px 0px 10px #0d0d0c, 0px 0px 0px 10px #0d0d0c;;
}

.status-text-wrapper {
  background-color: rgba(8, 12, 11, 0.5);
  margin: 5px 0px;
  padding: 15px 0 6px;
}

.decorative-wrapper {
  border-top: 10px solid rgba(8, 12, 11, 0.3);
  border-bottom: 10px solid rgba(8, 12, 11, 0.3);
  margin: 28px 0;
}

.status-text-wrapper .normal-status {
  margin: 0;
}

.status-text-wrapper .bold-status {
  color: #887c2d;
  font-size: 40px;
  font-weight: bolder;
  margin: 0;
  letter-spacing: 5px;
}
</style>
