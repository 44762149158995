<template>
    <div class="hero-image"></div>

    <div class="uk-container landing-page-format">
        <div class="uk-margin-large-left uk-margin-large-right">
            <div class="uk-flex uk-height-medium">
                <div class="uk-margin-auto uk-margin-auto-vertical uk-width-3-5@m">
                    <div class="twisted-stacked-logo"></div>
                    <h1 class="uk-width-2-3@s faction-summary uk-margin-large-bottom">A war-focused Torn faction and proud member of the CSY alliance.</h1>
                    <router-link to="/twisted-syndicate/email">
                        <button class="uk-button uk-button-large uk-button-primary uk-width-1-1">
                            <span uk-icon="icon: mail" class="uk-margin-small-right"></span>
                            Continue with Email
                        </button>
                    </router-link>
                    <p class="uk-width-3-5@l uk-margin-auto uk-text-center uk-margin-medium-top">By continuing, you agree with Twisted Syndicate's Terms of Services and Privacy Policy</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script></script>

<style scoped>
.hero-image {
    position: fixed;
    top: 0;
    left: 0;
    height: 70vh;
    background-image: url('~@/assets/twisted-syn.jpeg');
    background-size: cover;
    background-position: 0 -35px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    z-index: -1;
}

.hero-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: 
        /* First gradient for bottom fade */
        linear-gradient(to bottom, rgba(20, 20, 20, 0) 50%, #141414 100%),
        /* Subtle radial gradient for vignette effect */
        radial-gradient(circle at top left, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%),
        radial-gradient(circle at top right, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%);
    z-index: -1;
    /* Combine the gradients to achieve the fade effect */
}

.landing-page-format {
    margin-top: 20vh;
}

.faction-summary {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 2.0rem;
    line-height: 2.7rem;
}

.twisted-stacked-logo {
    background-image: url(@/assets/twisted-stacked-w.svg);
    background-repeat: no-repeat;
    height: 120px;
}

.uk-button {
    border-radius: 40px;
    text-transform: initial;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .25px;
}
</style>