<template>
    <div class="hero-image"></div>

    <div class="uk-container email-page-format">
        <div class="uk-margin-large-left uk-margin-large-right">
            <div class="uk-flex uk-height-medium">
                <div class="uk-margin-auto uk-margin-auto-vertical uk-width-3-5@m">
                    <router-link to="/twisted-syndicate/email">
                        <button class="uk-button uk-button-small uk-button-default back-button uk-margin-large-top">
                            <span uk-icon="icon: arrow-left"></span>
                        </button>
                    </router-link>
                    <h1 class="uk-width-1-1 faction-summary">Sign Up</h1>
                    <p class="uk-width-1-1 uk-margin-large-bottom">
                        Using <span style="color: white">{{ email }}</span> to sign up
                    </p>

                    <!-- Error Message Section -->
                    <div v-if="formError" class="uk-alert uk-alert-danger">
                        <strong>Error!</strong> {{ formError }}
                    </div>

                    <form class="uk-form-stacked">
                        <div class="uk-inline uk-width-1-1">
                            <label class="uk-form-label">
                                YOUR TORN PROFILE
                                <div 
                                    tooltip="Please select your Torn profile from the available list of active Twisted Syndicate members below." 
                                    tooltip-position="right">
                                    <span class="help-tooltip" uk-icon="icon: question; ratio: .80" style="color: #3E95EC; margin-left: 5px;"></span>
                                </div>
                            </label>
                            <div class="uk-form-controls">
                                <multiselect 
                                    v-model="value" 
                                    class="tornProfileSelect"
                                    :options="options" 
                                    :custom-label="customLabel" 
                                    :searchable="false" 
                                    :close-on-select="true" 
                                    :show-labels="false" 
                                    placeholder="Select a member">
                                    <template #option="{ option }">
                                        <div class="custom-option">
                                            <img :src="option.avatar" alt="Avatar" class="avatar" />
                                            <span>{{ option.label }}</span>
                                        </div>
                                    </template>
                                    <template #singleLabel="{ option }">
                                        <div class="custom-selected">
                                            <img :src="option.avatar" alt="Avatar" class="avatar" />
                                            <span>{{ option.label }}</span>
                                        </div>
                                    </template>
                                </multiselect>
                                <button 
                                    v-if="value"
                                    type="button" 
                                    class="uk-form-icon uk-form-icon-flip clear-field" 
                                    uk-icon="close" 
                                    @click="clearField('value')">
                                </button>
                            </div>
                        </div>

                        <div v-if="showApiKey" class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="apiKeyField">
                                YOUR API KEY
                                <div 
                                    tooltip="Please input a Torn API key with Limited access or higher." 
                                    tooltip-position="right">
                                    <span class="help-tooltip" uk-icon="icon: question; ratio: .80" style="color: #3E95EC; margin-left: 5px;"></span>
                                </div>
                            </label>
                            <div class="uk-form-controls api-wrapper">
                                <input 
                                    id="apiKeyField" 
                                    v-model="apiKey" 
                                    class="uk-input" 
                                    type="text" 
                                    placeholder="API Key" 
                                    @input="onApiKeyInput">
                                <button 
                                    v-if="apiKey"
                                    type="button" 
                                    class="uk-form-icon uk-form-icon-flip clear-field" 
                                    uk-icon="close" 
                                    @click="clearField('apiKey')">
                                </button>
                                <button 
                                    v-if="apiKey"
                                    type="button" 
                                    class="uk-button uk-button-primary uk-button-small uk-margin-small-top validate-api" 
                                    :disabled="!apiKey || !value"
                                    @click="validateApiKey">
                                    Validate API Key
                                </button>
                                <div v-if="accessLevelAchieved" :class="['access-chip', accessChipClass]">
                                    <span 
                                        :uk-icon="['Limited', 'Full'].some(level => accessLevelAchieved.includes(level)) 
                                            ? 'icon: check; ratio: .65' 
                                            : 'icon: close; ratio: .65'">
                                    </span>
                                    {{ accessLevelAchieved }}
                                </div>
                            </div>
                            <span v-if="apiKeyError" class="uk-text-danger validate-api-error">{{ apiKeyError }}</span>
                            <span v-if="loadingApiKey" class="uk-text-primary loading-message"><div uk-spinner="ratio: .5" style="margin-right:5px;"></div> Loading... Please wait while we fetch your profile data from the Torn API.</span>

                        </div>

                        <div v-if="showPassword" class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="passwordField">YOUR PASSWORD</label>
                            <div class="uk-form-controls">
                                <input 
                                    id="passwordField" 
                                    v-model="password" 
                                    class="uk-input" 
                                    type="password" 
                                    placeholder="Password" 
                                    @input="onPasswordInput">
                                <button 
                                    v-if="password"
                                    type="button" 
                                    class="uk-form-icon uk-form-icon-flip clear-field" 
                                    style="bottom: 21px;"
                                    uk-icon="close" 
                                    @click="clearField('password')">
                                </button>
                            </div>
                            <span style="position: relative;top: -21px;" v-if="passwordError" class="uk-text-danger">{{ passwordError }}</span> <!-- Error message for weak password -->
                        </div>
                    </form>
                    <button 
                        :disabled="!canSubmit || loadingApi || loadingApiKey"
                        class="uk-button uk-button-large uk-button-primary uk-width-1-1"
                        @click="createPayload">
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    components: { Multiselect },
    data() {
        return {
            members: [], // Raw member data fetched from the API
            email: null, // Placeholder for stored email
            value: null, // Holds the selected profile
            apiKey: '', // User's entered API key
            password: '', // User's entered password
            passwordError: '', // Holds error message for invalid password
            showPassword: false, // Controls visibility of the password field
            canSubmit: false, // Controls whether the Sign Up button is enabled
            apiKeyError: null, // Holds error message for invalid API key
            accessLevelAchieved: null, // Holds the access level achieved by the API key
            loadingApi: false, // Controls the loading state of the API call
            loadingApiKey: false, // Tracks the loading state for API key validation
            loadingApiRequest: false, // New loading state for POST request
            formError: '', // Holds the error message for form submission failure
            formSuccess: '', // Holds the success message if needed
        };
    },
    computed: {
        options() {
            return this.members
                .map(member => ({
                    label: `${member.member_name} [${member.member_id}]`,
                    value: member.member_id,
                    avatar: member.profile_image || this.getPlaceholderImage(member.gender),
                }))
                .sort((a, b) => a.label.localeCompare(b.label)); // Sort by label
        },
        showApiKey() {
            // Dynamically determine if the API Key field should be visible
            return !!this.value;
        },
        accessChipClass() {
            // Determine chip class dynamically based on access level
            switch (this.accessLevelAchieved) {
                case 'Public Access': return 'chip-public';
                case 'Minimal Access': return 'chip-minimal';
                case 'Limited Access': return 'chip-limited';
                case 'Full Access': return 'chip-full';
                default: return '';
            }
        },
    },
    methods: {
        async fetchMembers() {
            this.loadingApi = true; // Set loading state to true before making the API call
            try {
                const response = await fetch('https://epearson.me:3000/api/twisted-minds/active-members');
                if (!response.ok) throw new Error('Failed to fetch members');
                const data = await response.json();
                this.members = data;
            } catch (error) {
                console.error('Error fetching members:', error);
            } finally {
                this.loadingApi = false; // Set loading state to false once the API call is complete
            }
        },
        saveEmail(email) {
            this.email = email;
            localStorage.setItem('userEmail', email);
        },
        getPlaceholderImage(gender) {
            switch (gender) {
                case 'male': return require('@/assets/user_placeholder.jpg');
                case 'female': return require('@/assets/user_placeholder.jpg');
                case 'enby': return require('@/assets/user_placeholder.jpg');
                default: return require('@/assets/user_placeholder.jpg');
            }
        },
        customLabel(option) {
            return option ? option.label : '';
        },
        onApiKeyInput() {
            this.showPassword = false; // Hide password initially when typing API key
            this.canSubmit = false;    // Prevent submit until key is validated
            this.apiKeyError = null;   // Clear any existing API key error
            this.accessLevelAchieved = null; // Reset access level chip
        },
        onPasswordInput() {
            // Check if password is at least 15 characters long
            const minLength = 15;
            const remainingChars = minLength - this.password.length;

            if (this.password.length < minLength) {
                this.passwordError = `Password must be at least 15 characters long and at least ${remainingChars} more are needed.`;
                this.canSubmit = false; // Keep the button disabled if password is invalid
            } else {
                this.passwordError = null;  // Clear any password error
                this.canSubmit = true;      // Enable the button if the password is valid
            }
        },
        clearMemberSelection() {
            this.value = null;
            this.showPassword = false;
            this.canSubmit = false;
        },
        clearField(fieldId) {
            if (fieldId === 'value') {
                // Clear everything below and related to profile selection
                this.value = null;
                this.apiKey = '';
                this.password = '';
                this.showPassword = false;
                this.canSubmit = false;
                this.apiKeyError = null;
                this.accessLevelAchieved = null;
            } else if (fieldId === 'apiKey') {
                // Clear API Key and fields below it
                this.apiKey = '';
                this.password = '';
                this.showPassword = false;
                this.canSubmit = false;
                this.apiKeyError = null;
                this.accessLevelAchieved = null;
            } else if (fieldId === 'password') {
                // Clear Password and update submit button
                this.password = '';
                this.passwordError = null;  // Reset password error
                this.canSubmit = false;     // Disable the submit button
            }
        },

        async validateApiKey() {
            if (!this.value || !this.apiKey) {
                this.apiKeyError = "Please select a member and enter an API key.";
                this.accessLevelAchieved = null; // Reset access level chip
                this.showPassword = false; // Hide password field
                return;
            }

            // Ensure this.value is directly the member_id (string)
            const memberId = this.value.value;

            if (typeof memberId !== "string" || !memberId.trim()) {
                this.apiKeyError = "Invalid member ID. Please select a valid member.";
                this.accessLevelAchieved = null; // Reset access level chip
                this.showPassword = false; // Hide password field
                return;
            }

            // Indicate that the API key validation is in progress
            this.loadingApiKey = true;
            this.apiKeyError = null; // Clear any previous errors
            this.accessLevelAchieved = null; // Reset access level

            const apiAccessLevels = [
                { level: "Public Access", url: `https://api.torn.com/user/${memberId}?selections=basic&key=${this.apiKey}` },
                { level: "Minimal Access", url: `https://api.torn.com/user/${memberId}?selections=ammo&key=${this.apiKey}` },
                { level: "Limited Access", url: `https://api.torn.com/user/${memberId}?selections=battlestats&key=${this.apiKey}` },
                { level: "Full Access", url: `https://api.torn.com/user/${memberId}?selections=log&key=${this.apiKey}` }
            ];

            try {
                let highestAccessLevel = null;

                // Validate API key for each access level
                for (const access of apiAccessLevels) {
                    const response = await fetch(access.url);
                    if (!response.ok) break;

                    const data = await response.json();
                    if (!data.error) {
                        highestAccessLevel = access.level;
                    } else {
                        break; // Stop checking on the first error
                    }
                }

                // Handle the results of the validation
                if (!highestAccessLevel) {
                    this.apiKeyError = "Invalid API key. Please check and try again.";
                    this.accessLevelAchieved = null; // Reset access level chip
                    this.showPassword = false; // Hide password field
                    return;
                }

                if (["Public Access", "Minimal Access"].includes(highestAccessLevel)) {
                    this.apiKeyError = "API key must have at least Limited access. Please provide a valid key.";
                    this.accessLevelAchieved = highestAccessLevel; // Show achieved level even if rejected
                    this.showPassword = false; // Hide password field
                    return;
                }

                // Successful validation
                this.apiKeyError = null;
                this.accessLevelAchieved = highestAccessLevel; // Update access level chip
                this.showPassword = true; // Show password field
            } catch (error) {
                this.apiKeyError = "Error while validating the API key. Please try again later.";
                this.accessLevelAchieved = null; // Reset access level chip
                this.showPassword = false; // Hide password field
            } finally {
                // Always reset the loading state
                this.loadingApiKey = false;
            }
        },

        // Updated createPayload function with additional checks and page redirection
        async createPayload() {
            // Check if required fields are filled
            if (!this.value || !this.apiKey || !this.password) {
                this.notificationMessage = 'Please select a member, enter an API key, and provide a password.';
                this.notificationType = 'error';
                return;
            }

            // Construct the payload for registration
            const payload = {
                member_id: this.value.value, // member_id from the selected option
                member_name: this.value.label.split(' [')[0], // Extract member_name from the label
                api_key: this.apiKey, // API key entered by the user
                email: this.email, // Email entered by the user
                password: this.password, // Password entered by the user
            };

            console.log('Generated Payload:', payload); // Log for testing

            try {
                // Send a POST request to the registration endpoint
                const response = await fetch('https://epearson.me:3000/api/register', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload) // Send the payload as the request body
                });

                // Check for successful response
                if (!response.ok) throw new Error('Failed to create payload');

                // Parse the response JSON
                const result = await response.json();

                // Handle token from the response
                if (result.token) {
                    localStorage.setItem('authToken', result.token); // Save the token in localStorage
                    console.log('Auth token saved:', result.token);
                } else {
                    console.warn('Token missing from the response');
                }

                // Show success message
                this.notificationMessage = 'Sign-up successful! Redirecting...';
                this.notificationType = 'success';

                // Redirect to the authenticated area after a short delay
                setTimeout(() => {
                    window.location.href = '/twisted-syndicate/dashboard'; // Redirect to the authenticated area
                }, 2000); // 2-second delay for user to see the success message

            } catch (error) {
                // Handle any errors that occur during the process
                this.notificationMessage = 'An error occurred. Please try again.';
                this.notificationType = 'error';
                console.error('Error during registration:', error); // Log the error to the console
            }
        }
    },

    mounted() {
        this.fetchMembers();  // Fetch members on component mount
        const storedEmail = localStorage.getItem('userEmail');
        if (storedEmail) {
            this.email = storedEmail;
        }
    },
};
</script>

<style scoped>
.hero-image {
    position: fixed;
    top: 0;
    left: 0;
    height: 70vh;
    background-image: url('~@/assets/twisted-syn.jpeg');
    background-size: cover;
    background-position: 0 -35px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .05;
    z-index: -1;
}

.hero-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0) 50%, #141414 100%),
                radial-gradient(circle at top left, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%),
                radial-gradient(circle at top right, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%);
    z-index: -1;
}

.email-page-format {
    margin-top: 1vh;
}

.faction-summary {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.0rem;
}

.uk-button {
    border-radius: 40px;
    text-transform: initial;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .25px;
}

.uk-button.back-button {
    border-radius: 10px;
    padding: 0 10px 2px 10px;
    color: white;
    margin-bottom: 10px;
}

.uk-form-controls {
    margin-top: 20px;
    margin-bottom: 40px;
}

.uk-form-label {
    color: #666;
    letter-spacing: .5px;
}

#passwordField.uk-input,
#passwordField.uk-input:focus,
#passwordField.uk-input:active,
#passwordField.uk-input:hover,
#memberName.uk-input,
#memberName.uk-input:focus,
#memberName.uk-input:active,
#memberName.uk-input:hover,
#apiKeyField.uk-input,
#apiKeyField.uk-input:focus,
#apiKeyField.uk-input:active,
#apiKeyField.uk-input:hover {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #666;
    padding: 0 0 15px 0;
    outline: 0;
    box-shadow: none;
}

#passwordField.uk-input:focus,
#passwordField.uk-input:active,
#memberName.uk-input:focus,
#memberName.uk-input:active,
#apiKeyField.uk-input:focus,
#apiKeyField.uk-input:active {
    color: #fff;
}

#passwordField:placeholder-shown + .clear-field,
#memberName:placeholder-shown + .clear-field,
#apiKeyField:placeholder-shown + .clear-field {
    top: -15px;
    opacity: 0;
    pointer-events: none;
}

#passwordField:not(:placeholder-shown) + .clear-field,
#memberName:not(:placeholder-shown) + .clear-field,
#apiKeyField:not(:placeholder-shown) + .clear-field {
    top: -15px; 
    opacity: 1;
    pointer-events: all;
    color: white;
}

#tornMemberName.uk-select.uk-form-large {
    height: 50px;
    font-size: 16px;
    background: #232323;
    color: white;
}

/* Style for the avatar images in the dropdown */
.custom-option {
    display: flex;
    align-items: center;
}

.custom-option .avatar,
.custom-selected .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
}

.tornProfileSelect + .clear-field {
    right: 32px;
}

.api-wrapper {
    position: relative;
}

.validate-api {
    position: absolute;
    top: -13px;
    right: 44px;
}

.validate-api-error,
.loading-message {
    position: relative;
    top: -30px;
    font-size: 12px;
    left: 1px;
}

.access-chip {
    position: absolute;
    right: 211px;
    padding: 4PX 8PX;
    MARGIN: 0;
    color: #32d296;
    border: 1px solid #32d296;
    top: -2px;
    font-size: 12px;
}

.chip-public,
.chip-minimal {
    color: #f0506e;
    border-color: #f0506e;
}

.access-chip .uk-icon {
    margin-right: 2px;
}

@media (max-width: 640px) {
    .validate-api {
        position: relative;
        top: 8px;
        left: 0;
        margin-bottom: 8px;
    }
    .access-chip {
        right: auto;
        left: 167px;
        top: 59px;
    }
    .api-wrapper .clear-field {
        height: 61px;
    }
}
</style>
