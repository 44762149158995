<template>
  <div class="visual-mode-dark public">
    <main>
      <router-view />
    </main>
    <footer></footer>
  </div>
</template>
  
<script>

</script>

<style>
html {
  background: #141414!important;
}
</style>