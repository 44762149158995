<template>
    <div class="hero-image"></div>

    <div class="uk-container email-page-format">
        <div class="uk-margin-large-left uk-margin-large-right">
            <div class="uk-flex uk-height-medium">
                <div class="uk-margin-auto uk-margin-auto-vertical uk-width-3-5@m">
                    <router-link to="/twisted-syndicate/">
                        <button class="uk-button uk-button-small uk-button-default back-button uk-margin-large-top">
                            <span uk-icon="icon: arrow-left"></span>
                        </button>
                    </router-link>
                    <h1 class="uk-width-1-1 faction-summary uk-margin-large-bottom">What's your<br>email<br>address?</h1>
                    <form @submit.prevent="checkEmail" class="uk-form-stacked">
                        <div class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="emailAddress">YOUR EMAIL</label>
                            <div class="uk-form-controls">
                                <input 
                                    id="emailAddress" 
                                    class="uk-input" 
                                    type="text" 
                                    v-model="email" 
                                    placeholder="Email Address"
                                >
                                <!-- Clear button -->
                                <button 
                                    type="button" 
                                    class="uk-form-icon uk-form-icon-flip clear-field" 
                                    uk-icon="close" 
                                    @click="clearEmail"
                                ></button>
                            </div>
                            <!-- Error message -->
                        </div>
                        <p v-if="error" class="uk-text-danger" style="position: relative;top: -21px;margin: 0;">{{ error }}</p>
                        <button 
                            class="uk-button uk-button-large uk-button-primary uk-width-1-1" 
                            style="top: 0;bottom: 57px;"
                            :disabled="!isEmailValid"
                        >
                            <span uk-icon="icon: mail" class="uk-margin-small-right"></span>
                            Continue with Email
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            email: '', // Email input field value
            error: null // Error message
        };
    },
    computed: {
        // Computed property to check if email is valid
        isEmailValid() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(this.email);
        }
    },
    methods: {
        // Clear the email field
        clearEmail() {
            this.email = '';
            this.error = null;
        },
        // Check email against the database
        async checkEmail() {
            try {
                if (!this.isEmailValid) {
                    this.error = 'Please enter a valid email address.';
                    return;
                }

                const response = await axios.post('https://epearson.me:3000/api/check-email', {
                    email: this.email
                });

                // Store email in localStorage
                localStorage.setItem('userEmail', this.email);

                // Redirect based on the API response
                if (response.data.exists) {
                    console.log('Email exists. Redirecting to /twisted-syndicate/login');
                    this.$router.push('/twisted-syndicate/login');
                } else {
                    console.log('Email does not exist. Redirecting to /twisted-syndicate/signup');
                    this.$router.push('/twisted-syndicate/signup');
                }
            } catch (err) {
                this.error = 'An error occurred. Please try again later.';
                console.error('Error during API request:', err);
            }
        }
    },
    mounted() {
        // Populate the email field if it exists in localStorage
        const storedEmail = localStorage.getItem('userEmail');
        if (storedEmail) {
            this.email = storedEmail;
        }
    }
};
</script>

<style scoped>
.hero-image {
    position: fixed;
    top: 0;
    left: 0;
    height: 70vh;
    background-image: url('~@/assets/twisted-syn.jpeg');
    background-size: cover;
    background-position: 0 -35px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .05;
    z-index: -1;
}

.hero-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: 
        /* First gradient for bottom fade */
        linear-gradient(to bottom, rgba(20, 20, 20, 0) 50%, #141414 100%),
        /* Subtle radial gradient for vignette effect */
        radial-gradient(circle at top left, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%),
        radial-gradient(circle at top right, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%);
    z-index: -1;
    /* Combine the gradients to achieve the fade effect */
}

.email-page-format {
    margin-top: 1vh;
}

.faction-summary {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.0rem;
}

.uk-button {
    border-radius: 40px;
    text-transform: initial;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .25px;
}

.uk-button.back-button {
    border-radius: 10px;
    padding: 0 10px 2px 10px;
    color: white;
    margin-bottom: 10px;
}

.uk-form-controls {
    margin-top: 20px;
    margin-bottom: 40px;
}

.uk-form-label {
    color: #666;
    letter-spacing: .5px;
}

#emailAddress.uk-input,
#emailAddress.uk-input:focus,
#emailAddress.uk-input:active,
#emailAddress.uk-input:hover {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #666;
    padding: 0 0 15px 0;
    outline: 0;
    box-shadow: none;
}

#emailAddress.uk-input:focus,
#emailAddress.uk-input:active {
    color: #fff;
}

#emailAddress:placeholder-shown + .clear-field {
    top: -15px;
    opacity: 0;
    pointer-events: none;
}

#emailAddress:not(:placeholder-shown) + .clear-field {
    top: -15px;
    opacity: 1;
    pointer-events: all;
    color: white;
}
</style>