<template>
    <div class="hero-image"></div>

    <div class="uk-container email-page-format">
        <div class="uk-margin-large-left uk-margin-large-right">
            <div class="uk-flex uk-height-medium">
                <div class="uk-margin-auto uk-margin-auto-vertical uk-width-3-5@m">
                    <router-link to="/twisted-syndicate/email">
                        <button class="uk-button uk-button-small uk-button-default back-button uk-margin-large-top">
                            <span uk-icon="icon: arrow-left"></span>
                        </button>
                    </router-link>
                    <h1 class="uk-width-1-1 faction-summary">Log in</h1>
                    <p class="uk-width-1-1 uk-margin-large-bottom">
                        Using <span style="color: white">{{ email }}</span> to log in
                    </p>
                    <form class="uk-form-stacked" @submit.prevent="handleLogin">
                        <div class="uk-inline uk-width-1-1">
                            <label class="uk-form-label" for="passwordField">YOUR PASSWORD</label>
                            <div class="uk-form-controls" style="margin-bottom: 20px;">
                                <input
                                    id="passwordField"
                                    v-model="password"
                                    class="uk-input"
                                    type="password"
                                    placeholder="Password"
                                />
                                <!-- Show clear button only if password is not empty -->
                                <button 
                                    v-if="password"
                                    type="button" 
                                    class="uk-form-icon uk-form-icon-flip clear-field" 
                                    uk-icon="close" 
                                    @click="clearPassword">
                                </button>
                            </div>
                        </div>
                        <p class="uk-text-right uk-margin-remove-top" style="margin-bottom: 35px;">
                            <router-link to="/twisted-syndicate/update-password">Forgot Password?</router-link>
                        </p>
                        <button
                            class="uk-button uk-button-large uk-button-primary uk-width-1-1"
                            type="submit"
                        >
                            Log In
                        </button>
                    </form>
                    <p v-if="errorMessage" class="uk-text-danger">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '', // Will be populated from localStorage
            password: '',
            errorMessage: '',
        };
    },
    mounted() {
        // Populate the email field if it exists in localStorage
        const storedEmail = localStorage.getItem('userEmail');
        if (storedEmail) {
            this.email = storedEmail;
        }
    },
    methods: {
        async handleLogin() {
            try {
                this.errorMessage = ''; // Clear any previous errors

                const response = await fetch('https://epearson.me:3000/api/log-in', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: this.email, password: this.password }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    this.errorMessage = errorData.message || 'An error occurred';
                    return;
                }

                const { token, message } = await response.json();
                localStorage.setItem('authToken', token); // Store the token
                console.log(message, token);

                // Redirect to the overview page
                this.$router.push('/twisted-syndicate/dashboard');
            } catch (error) {
                console.error('Login error:', error);
                this.errorMessage = 'Failed to log in. Please try again.';
            }
        },
        clearPassword() {
            this.password = ''; // Clear the password field
        },
    },
};
</script>

<style scoped>
.hero-image {
    position: fixed;
    top: 0;
    left: 0;
    height: 70vh;
    background-image: url('~@/assets/twisted-syn.jpeg');
    background-size: cover;
    background-position: 0 -35px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .05;
    z-index: -1;
}

.hero-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: 
        /* First gradient for bottom fade */
        linear-gradient(to bottom, rgba(20, 20, 20, 0) 50%, #141414 100%),
        /* Subtle radial gradient for vignette effect */
        radial-gradient(circle at top left, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%),
        radial-gradient(circle at top right, rgba(20, 20, 20, 0) 50%, rgba(20, 20, 20, 40) 88%);
    z-index: -1;
    /* Combine the gradients to achieve the fade effect */
}

.email-page-format {
    margin-top: 1vh;
}

.faction-summary {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.0rem;
}

.uk-button {
    border-radius: 40px;
    text-transform: initial;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .25px;
}

.uk-button.back-button {
    border-radius: 10px;
    padding: 0 10px 2px 10px;
    color: white;
    margin-bottom: 10px;
}

.uk-form-controls {
    margin-top: 20px;
    margin-bottom: 40px;
}

.uk-form-label {
    color: #666;
    letter-spacing: .5px;
}

#passwordField.uk-input,
#passwordField.uk-input:focus,
#passwordField.uk-input:active,
#passwordField.uk-input:hover {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #666;
    padding: 0 0 15px 0;
    outline: 0;
    box-shadow: none;
}

#passwordField.uk-input:focus,
#passwordField.uk-input:active {
    color: #fff;
}

#passwordField:placeholder-shown + .clear-field {
    top: -15px;
    opacity: 0;
    pointer-events: none;
}

#passwordField:not(:placeholder-shown) + .clear-field {
    top: 0px;
    opacity: 1;
    pointer-events: all;
    color: white;
}
</style>