<template>
  <nav class="uk-navbar-container filter-set-wrapper" uk-navbar="offset: -.5;">
    <div class="uk-navbar-left">
        <div class="uk-position-left">
          <h4 class="uk-inline" style="font-weight: 500;padding-top: 13px;">
            <span>War Hits Made: </span>
            <span style="color: #BA78D4;"> {{ totalHits.toLocaleString() }}</span>
            <!--<span>War Respect Earned: </span>
            <span style="color: #BA78D4;"> {{ totalRespect.toLocaleString() }}</span>-->
          </h4>
        </div>
    </div>
    <div class="uk-navbar-right filter-set">
        <div class="uk-position-right">
          <ul class="uk-navbar-nav">
            <li>
              <span class="uk-text-small uk-text-muted small-screen-hide" style="line-height: 30px; padding-right: 20px;">
                {{ localSelectedWarId ? 'War ID: ' + localSelectedWarId : 'No war ID selected' }}
              </span>
              <div class="uk-inline uk-inline-select-overlayed">
                <select
                  id="warIdSelector"
                  class="uk-select uk-form-default uk-text-right"
                  v-model="localSelectedWarId"
                  @change="emitWarIdChange"
                >
                  <option v-for="war in sortedWarIds" :key="war.id" :value="war.id">
                    <span>{{ war.displayDate }}</span>
                  </option>
                </select>
                <label for="warIdSelector">War Date</label>
              </div>
            </li>
            <li>
              <fieldset class="multiswitch hits-by-respect">
                <div class="slide-container">
                  <input type="radio" id="hits" value="hits" v-model="selectedMetric" checked>
                  <label for="hits">Hits</label>
                  
                  <input type="radio" id="respect" value="respect" v-model="selectedMetric">
                  <label for="respect">Respect</label>
                  
                  <a class="slide" aria-hidden="true"></a>
                </div>
              </fieldset>
            </li>
          </ul>
        </div>
    </div>
  </nav>

  <div class="uk-container uk-container-expand" style="margin-bottom: 15px;">
    <div class="uk-width-1-1">
      <br>      <br>      <br>
    </div>
  </div>

  <div class="uk-container uk-container-expand">
    <div class="uk-width-1-1">
      <!-- War Data Display -->
        <div v-if="loading">
          <p>Loading data...</p>
        </div>
        <div v-else>
          <!-- Canvas elements always present -->
          <canvas id="warChartHits" width="466" height="75" v-show="selectedMetric === 'hits'"></canvas>
          <canvas id="warChartRespect" width="466" height="75" v-show="selectedMetric === 'respect'"></canvas>
          <div>&nbsp;</div>

          <!-- Member Table -->
          <table class="uk-table uk-table-small uk-table-divider uk-table-justify uk-margin-remove-bottom">
            <thead>
              <tr>
                <th class="uk-table-expand">Member Name</th>
                <th class="uk-width-1-5">Attacks</th>
                <th class="uk-width-1-5 uk-text-right">Respect</th>
                <th class="uk-width-0 uk-padding-remove">&nbsp;</th>
              </tr>
            </thead>
          </table>
          <div class="uk-table-scrollable uk-table-max-height-205">
            <table class="uk-table uk-table-small uk-table-divider uk-table-justify uk-margin-remove-bottom">
              <tbody>
                <tr v-for="member in formattedMembers" :key="member.member_id">
                  <td class="uk-table-expand">{{ member.member_name }}</td>
                  <td class="uk-width-1-5">{{ member.member_attacks }}</td>
                  <td class="uk-width-1-5 uk-text-right">{{ member.formattedScore }}</td>
                  <td class="uk-width-0 uk-padding-remove">&nbsp;</td>
                </tr>
                <tr v-if="!formattedMembers.length">
                  <td colspan="4" class="uk-text-center">No data available for this war</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { nextTick } from 'vue';

Chart.register(...registerables);

export default {
  props: {
    warIds: {
      type: Array,
      default: () => []
    },
    factionData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localSelectedWarId: '',
      chartInstances: {
        hits: null,
        respect: null,
      },
      selectedMetric: 'hits',
      ready: false,
      loading: true,
      previousWarId: null,
      previousHitsData: null,
      previousRespectData: null,
      totalHits: 0, // Initialize totalHits
      totalRespect: 0 // Initialize totalRespect
    };
  },
  computed: {
    sortedWarIds() {
      if (!Array.isArray(this.warIds) || this.warIds.length === 0) {
        // console.warn('sortedWarIds: warIds is either not an array or empty.');
        return [];
      }
      return [...this.warIds].sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    sortedMembers() {
      const selectedWarData = this.factionData.find(item => item.war_id === Number(this.localSelectedWarId));
      if (!selectedWarData || !Array.isArray(selectedWarData.members)) {
        return [];
      }

      const memberData = selectedWarData.members.filter(member => member.member_attacks > 0 || member.member_score > 0);
      return this.selectedMetric === 'hits'
        ? memberData.sort((a, b) => b.member_attacks - a.member_attacks)
        : memberData.sort((a, b) => b.member_score - a.member_score);
    },
    formattedMembers() {
      return this.sortedMembers.map(member => ({
        ...member,
        formattedScore: member.member_score.toFixed(2) // Format respect to two decimal places
      }));
    }
  },
  watch: {
    warIds(newVal) {
      if (Array.isArray(newVal) && newVal.length > 0) {
        this.populateLocalData();
      } else {
        console.warn('warIds is invalid or empty during watch.');
      }
    },
    factionData(newVal) {
      if (Array.isArray(newVal) && newVal.length > 0) {
        this.checkReadiness();
      } else {
        console.warn('factionData is invalid or empty during watch.');
      }
    },
    selectedMetric() {
      if (this.ready && this.localSelectedWarId) {
        this.updateChart();
      }
    },
    localSelectedWarId(newVal) {
      if (this.ready && newVal !== this.previousWarId) {
        this.previousWarId = newVal; // Update previousWarId
        this.updateChart();
        this.updateTotalHits(); // Update totalHits when the selected war ID changes
        this.updateTotalRespect(); // Update totalRespect when the selected war ID changes
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      //console.log('Component mounted. Verifying DOM elements after DOM update.');
      this.checkReadiness();
    });
  },
  methods: {
    populateLocalData() {
      //console.log('Populating localData with warIds...');
      if (!Array.isArray(this.sortedWarIds) || this.sortedWarIds.length === 0) {
        console.warn('populateLocalData: No valid warIds available.');
        return;
      }

      // Select the most recent war ID
      this.localSelectedWarId = this.sortedWarIds[0]?.id || '';
      this.previousWarId = this.localSelectedWarId; // Set previousWarId to the initial value
      //console.log('Most recent war date:', this.sortedWarIds[0]?.date);
      //console.log('Local Selected War ID:', this.localSelectedWarId);
    },
    checkReadiness() {
      if (!Array.isArray(this.factionData) || this.factionData.length === 0) {
        // console.warn('checkReadiness: factionData is not ready.');
        return;
      }

      this.ready = true;
      this.loading = false;
      this.$nextTick(() => {
        if (this.localSelectedWarId) {
          this.updateChart();
          this.updateTotalHits(); // Ensure totalHits is updated on initial load
          this.updateTotalRespect(); // Ensure totalRespect is updated on initial load
        }
      });
    },
    async updateChart() {
      await nextTick(); // Ensure DOM updates are complete

      if (!this.ready || !this.localSelectedWarId) {
        console.warn('updateChart: Component is not ready or no selected war ID.');
        return;
      }

      const selectedWarIdNumber = Number(this.localSelectedWarId);
      const selectedWarData = this.factionData.find(item => item.war_id === selectedWarIdNumber);

      if (!selectedWarData || !Array.isArray(selectedWarData.members)) {
        console.error('updateChart: Selected war data or members are invalid.');
        console.log('Selected War ID:', selectedWarIdNumber);
        console.log('Selected War Data:', selectedWarData);
        return;
      }

      const memberData = selectedWarData.members;

      // Filter out zero values and sort by data value from highest to lowest
      const hitsData = memberData
        .filter(member => member.member_attacks > 0)
        .sort((a, b) => b.member_attacks - a.member_attacks);

      const respectData = memberData
        .filter(member => member.member_score > 0)
        .sort((a, b) => b.member_score - a.member_score);

      // Only update chart if data has changed
      if (JSON.stringify(this.previousHitsData) !== JSON.stringify(hitsData)) {
        this.previousHitsData = hitsData;
        this.updateChartInstance('hits', this.createChartData(hitsData, 'Total Hits', 'rgb(129, 125, 242)'));
      }

      if (JSON.stringify(this.previousRespectData) !== JSON.stringify(respectData)) {
        this.previousRespectData = respectData;
        this.updateChartInstance('respect', this.createChartData(respectData, 'Total Respect', 'rgb(186, 120, 212)'));
      }
    },

    createChartData(data, label, color) {
      return {
        labels: data.map(member => member.member_name),
        datasets: [{
          label: label,
          data: data.map(member => label === 'Total Hits' ? member.member_attacks : member.member_score),
          backgroundColor: color,
          borderWidth: 0
        }]
      };
    },

    updateChartInstance(type, data) {
      const canvas = document.getElementById(`warChart${type.charAt(0).toUpperCase() + type.slice(1)}`);
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        console.error('Failed to get context for canvas element.');
        return;
      }

      if (this.chartInstances[type]) {
        this.chartInstances[type].destroy();
      }

      this.chartInstances[type] = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          scales: {
            x: {
              display: false,
              type: 'category',
              beginAtZero: true,
              ticks: {
                autoSkip: false,
                display: false
              },
              grid: {
                display: false
              }
            },
            y: {
              display: false,
              type: 'linear',
              beginAtZero: true,
              grid: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    },

    updateTotalHits() {
      const selectedWarIdNumber = Number(this.localSelectedWarId);
      const selectedWarData = this.factionData.find(item => item.war_id === selectedWarIdNumber);

      if (selectedWarData) {
        this.totalHits = selectedWarData.attacks;
      } else {
        this.totalHits = 0; // Set to 0 if no data available
      }
    },

    updateTotalRespect() {
      const selectedWarIdNumber = Number(this.localSelectedWarId);
      const selectedWarData = this.factionData.find(item => item.war_id === selectedWarIdNumber);

      if (selectedWarData) {
        this.totalRespect = Math.round(selectedWarData.respect); // Round respect to nearest whole number
      } else {
        this.totalRespect = 0; // Set to 0 if no data available
      }
    }
  }
};
</script>

<style scoped>
/* Your CSS styling here */
.uk-table-scrollable {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  scrollbar-gutter: stable both-edges;
}

.uk-table-max-height-205 {
  max-height: 383px;
}

.uk-width-0 {
  width: 0px;
}

label:has([type=radio]) {
  transition: background-color 0.3s;
  width: 124px;
  height: 121px;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  border-left: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding-left: 43px;
  padding-bottom: 25px;
}

label:has([type=radio]:not(:disabled)) {
  cursor: pointer;
}

[type=radio] {
  appearance: none;
}

label:has([type=radio]:checked) {
  background-color: #f4f4f5;
}
</style>
